export const environment = {
  production: true,
  hmr: false,
  debug: false,
  client: 'VW-US',
  hostName: '',
  environmentName: 'production',
  appName: 'VW-US Analytics',
  ssoPassword: 'ShiftDigital#123',
  applicationInsights: {
    instrumentationKey: 'f6ad882f-4143-4e9a-82e6-34498d29094d'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'true',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#1b365c', '#47ddfc', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://vwus-analytics-api-v4-prod.azurewebsites.net/api',
  authUri: 'https://vwus-analytics-api-v4-prod.azurewebsites.net/token',
  baseV5ApiUri: 'https://vwus-analytics-apiv5-prod.azurewebsites.net/api',
  retailerToolsBaseUri: 'https://vwus-retailertools-web-prod.azurewebsites.net',
  retailerToolsIncludeAuth: true,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/vw-us/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: ['WebsiteOverview','DigitalAdvertisingDashboard']
};
